import axios from "axios";

const Token = process.env.REACT_APP_API_ELEVENLABS_TOKEN

const axiosElevenLabs = axios.create({
    mode: 'no-cors',
    headers: {
        "xi-api-key": Token,
        "Content-Type": 'application/json',
    }
});

const axiosElevenLabsTextToSpeech = axios.create({
    mode: 'no-cors', 
    headers:{
        "xi-api-key": Token,
        "Content-Type": 'application/json',
        accept: 'audio/mpeg',
    },
    responseType: 'arraybuffer',
})

async function obtainVoices() {
    // const response = await axiosElevenLabs.get(`https://corsproxy.io/?https://api.elevenlabs.io/v1/voices`);
    // const response = await axiosElevenLabs.get(`https://api.elevenlabs.io/v1/voices`);

    const response = await axios.get('/elevenlabs/voices');
    return response.data;
    // const response = await axiosElevenLabs.get(`eleven/v1/voices`);
    // return response.data;
}

async function obtainAudio(voiceId, body) {
    // const response = await axiosElevenLabsTextToSpeech.post(`https://corsproxy.io/?https://api.elevenlabs.io/v1/text-to-speech/${voiceId}`, body)
    const response = await axios.post(`/elevenlabs/text-to-speech/${voiceId}`, body, {
        responseType: 'arraybuffer' // Para recibir el audio como buffer
    })
    return response.data
}



export {
    obtainVoices,
    obtainAudio,
}